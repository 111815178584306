import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { watch } from "vue";

export default defineComponent({
  data() {
    return {
      dToggle: false,
      iToggle: false,
      cToggle: false,
      titleText: "",
    };
  },
  created() {
    this.titleText = "ubc jazz club";
    const route = useRoute();
    watch(
      () => route.path,
      async (newPath) => {
        this.update(newPath);
      }
    );
  },
  methods: {
    update(route: string) {
      if (route == "/") {
        this.titleText = "ubc jazz club";
      } else if (route == "/contact") {
        this.dToggle = true;
      } else if (route == "/about") {
        this.titleText = "about us";
        this.iToggle = true;
        this.cToggle = true;
      } else if (route == "/team") {
        this.titleText = "our team";
        this.iToggle = true;
        this.cToggle = true;
      } else if (route == "/involvement") {
        this.titleText = "get involved";
        this.iToggle = true;
        this.cToggle = true;
      } else if (route == "/gallery") {
        this.titleText = "gallery";
        this.iToggle = true;
        this.cToggle = true;
      } else if (route == "/events") {
        this.titleText = "events";
        this.iToggle = true;
        this.cToggle = true;
      }
    },
  },
});
