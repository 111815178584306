import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-495d0582"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "footer-container" }
const _hoisted_2 = { class: "footer-content" }
const _hoisted_3 = { class: "footer-row info" }
const _hoisted_4 = { class: "footer-row item socials" }
const _hoisted_5 = { href: "https://www.instagram.com/ubcjazzclub/?hl=en" }
const _hoisted_6 = { href: "https://discord.com/invite/dsznYSCyeM" }
const _hoisted_7 = { href: "https://www.facebook.com/ubcjazzclub" }
const _hoisted_8 = { class: "footer-row item" }
const _hoisted_9 = {
  href: "mailto:president@ubcjazzclub.ca",
  class: "icon"
}
const _hoisted_10 = { class: "footer-row item" }
const _hoisted_11 = {
  href: "https://goo.gl/maps/3w3ruTpXCaUUpnzU7",
  class: "icon"
}
const _hoisted_12 = { class: "wrapper-loc" }
const _hoisted_13 = { class: "footer-copyright" }

export function render(_ctx, _cache) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "footer-return",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.scrollToTop && _ctx.scrollToTop(...args)))
    }, "back to the top"),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("a", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'instagram'] })
          ]),
          _createElementVNode("a", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'discord'] })
          ]),
          _createElementVNode("a", _hoisted_7, [
            _createVNode(_component_font_awesome_icon, { icon: ['fab', 'facebook'] })
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("a", _hoisted_9, [
            _createVNode(_component_font_awesome_icon, { icon: ['far', 'envelope'] })
          ]),
          _createElementVNode("p", null, _toDisplayString(this.email), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("a", _hoisted_11, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'location-dot'] })
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", null, _toDisplayString(this.room), 1),
            _createElementVNode("p", null, _toDisplayString(this.address), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.copyright), 1)
  ]))
}