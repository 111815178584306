
import JazzHeader from "./layouts/Header/JazzHeader.vue";
import JazzTitle from "./layouts/Title/JazzTitle.vue";
import JazzFooter from "./layouts/Footer/JazzFooter.vue";
import Background from "./layouts/Background/Background.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    JazzHeader,
    JazzTitle,
    JazzFooter,
    Background,
  },
});
